<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'enable_form',
    };
  },

  methods: {

    // 设置规则
    setRule(item) {
      const v = item;
      return item;
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      console.log(formData, 222);
      formData.ids = this.formConfig.selection.map((v) => v);
      // 默认新增
      const url = '/tpm/tpmCostRules/enableAndSetEndDate';
      request.post(url, formData).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
/deep/.el-col-md-24{
  margin-left: -20px !important;
}

</style>
