var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-select" },
    [
      _c(
        "div",
        { staticClass: "cost-select", on: { click: _vm.openModal } },
        [
          _c("el-input", {
            attrs: {
              placeholder: _vm.title,
              disabled: _vm.disabled,
              readonly: true,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _vm.value && !_vm.disabled
            ? _c("div", { staticClass: "clear-icon" }, [
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: { click: _vm.clearFn },
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }