<template>
    <div class="addUser-position-information">
      <el-form  :model="infoObj" label-position="right" ref="infoObjFormRef" label-width="100px">
          <el-row v-for="(item,index) in infoObj.itemList" :key="index">
            <el-col :span="24">
              <div class="arrItemTitle">
                <div class="arrItemTitle-left">
                  <p>
                    费用科目规则参数{{index + 1}}
                  </p>
                  <div v-if="!isDisable || formConfig.type !== 'view'">
                    <el-button v-if="isDisable && index === 0" type="text" @click="addArrItem(infoObj.itemList)">
                      <i style="font-size: 20px" class="el-icon-circle-plus-outline"></i>
                    </el-button>
                    <el-button v-if="isDisable && index>0" type="text" @click="delArrItem(infoObj.itemList,index)">
                      <i style="font-size: 20px" class="el-icon-delete"></i>
                    </el-button>
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :span="8">
              <el-form-item label="预算科目"  :prop="'itemList.' + index + '.budgetSubjectCode'"
                :rules="{required: true, message: '操作类型不能为空', trigger: 'change'}">
                <el-select remote
                  @change="changeBudgetList(item,index)"
                  clearable
                  filterable
                   :disabled="!isDisable || formConfig.type === 'view'"
                  v-model="item.budgetSubjectCode">
                  <el-option v-for="(item,index) in budgetAccount" :key="index" :label="item.budgetSubjectsName" :value="item.budgetSubjectsCode" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item
                :prop="'itemList.' + index + '.amount'"
                maxlength="8"
                :rules="{required: true,message: '请输入合法的金额数字，最多四位小数',trigger: 'blur'}"
                label="金额">
                <!-- <el-input placeholder="请输入金额" :disabled="!isDisable || formConfig.type === 'view'"  v-model="item.amount"></el-input> -->
              <el-input-number v-model="item.amount" :precision="4"></el-input-number>
             </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item
                :prop="'itemList.' + index + '.remarks'"
                maxlength="200"
                label="备注">
                <el-input placeholder="请输入备注" :disabled="!isDisable || formConfig.type === 'view'" v-model="item.remarks"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
      </el-form>
    </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'FeeSuject',
  components: {},
  props: {
    isDisable: {
      type: Boolean,
      default: true,
    },
    saveRef: Function,
    budgetAccount: { // 预算科目
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Array,
      default: () => ([]),
    },
    formConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      infoObj: {
        itemList: [
          {
            id: '',
            budgetSubjectCode: '',
            budgetSubjectName: '',
            amount: '',
            remarks: '',
          },
        ],

      },
    };
  },
  async created() {
    console.log('基础信息', this.formConfig, this.isDisable);
    if (this.saveRef) {
      this.saveRef(this);
    }
    if (this.formConfig.row && this.formConfig.row.id) {
      this.getDetail(this.formConfig.row.id);
    }
  },
  watch: {
    'infoObj.itemList': {
      deep: true,
      immediate: true,
      handler(itemList) {
        this.$emit('input', itemList);
        this.$emit('change', itemList);
      },
    },
    isDisable(newVal, oldVal) {
      this.isDisable = newVal;
    },
  },
  methods: {
    // 清空预算科目
    clearBudgetSubject() {
      this.infoObj.itemList.forEach((item) => {
        item.budgetSubjectCode = '';
        item.budgetSubjectName = '';
      });
    },
    // 改变预算科目
    changeBudgetList(val, index) {
      const item = val;
      if (item.budgetSubjectCode) {
        this.budgetAccount.forEach((ba, j) => {
          if (item.budgetSubjectCode === ba.budgetSubjectsCode) {
            item.budgetSubjectName = ba.budgetSubjectsName;
          }
        });
        this.infoObj.itemList[index] = item;
      } else {
        this.infoObj.itemList[index].budgetSubjectName = '';
        this.infoObj.itemList[index].budgetSubjectCode = '';
      }
    },

    // 根据 id  获取 详情
    async getDetail(id = '') {
      request.get('/tpm/tpmCostRules/query', { id }).then((res) => {
        if (res.success) {
          this.infoObj = res.result;
        }
      });
    },

    submitInfoObj() {
      let isRight = false;
      this.$refs.infoObjFormRef.validate((valid) => {
        if (valid) {
          isRight = valid;
        }
      });
      return isRight;
    },

    //  新增每一i想
    addArrItem(arr) {
      arr.push(
        {
          budgetSubjectCode: '',
          amount: '',
          remarks: '',
        },
      );
    },

    // 删除每一项
    delArrItem(arr, index) {
      this.$confirm('确认删除吗？', '提示').then(() => {
        arr.splice(index, 1);
      });
    },

    // 返回数据
    backData() {
      return this.infoObj.itemList;
    },
  },

};
</script>

<style scoped lang="less">
/deep/.el-button--small.is-circle{
  padding: 5px!important;
}
/deep/.el-form-item__label{
  font-size: 12px!important;
}
  .addUser-position-information{
    width: 100%;
    .footer-btn {
      position: absolute;
      bottom: 0;
      right: 10px;

      button {
        width: 100px;
      }
    }

    .arrItemTitle{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .arrItemTitle-left{
        display: flex;
        align-items: center;
        justify-content: center;
        p{
          margin: 0 20px 0 0;
          font-size: 16px;
          height: 20px;
          line-height: 20px;
          font-weight: 600;
        }

      }
      .arrItemTitle-right{

      }

    }

  }

</style>
